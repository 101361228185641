import axios from 'axios'

import { postMessageToParent } from 'utils/postMessageToParent'
const AUTHOR_URL = `${import.meta.env.REACT_APP_AEM_AUTHOR}`

/**
 * post-data-to-aem
 * This function posts page data to the AEM author instance
 * @param { string } path Path to the page content
 * @param { json } data page data object
 * @returns promise, probably a boolean
 */
const postDataToAem = (path, data, key = 'POST_DATA_TO_AEM') => {
  const url = `${AUTHOR_URL}${path}`

  if (window.top !== window.self) {
    return postMessageToParent<any>(
      { type: 'POST', key, path, data: JSON.stringify(data) },
      AUTHOR_URL,
      (message, resolve, reject) => {
        if (message.status) {
          resolve(message.data)
        } else {
          reject(message)
        }
      }
    )
  } else {
    return axios.post<any>(url, data)
  }
}

export default postDataToAem
