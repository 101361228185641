import axios from 'axios'

import { postMessageToParent } from 'utils/postMessageToParent'
const AUTHOR_URL = `${import.meta.env.REACT_APP_AEM_AUTHOR || '*'}`

export const getDataFromAEM = async (path) => {
  const url = `${AUTHOR_URL}${path}`
  const key = `GET_AEM_PAGE-${path}`
  const messageToPost = { type: 'GET', path, key }

  // if there is a parent window, forward the data to it instead and wait for response
  return await getDataFromParentOrViaXhr(key, messageToPost, url, AUTHOR_URL)
}

export default getDataFromAEM

export async function getDataFromParentOrViaXhr(key, messageToPost, url, AUTHOR_URL) {
  let res = null
  if (window.top !== window.self) {
    res = await postMessageToParent(messageToPost, AUTHOR_URL, (message, resolve, reject) => {
      if (message.status) {
        resolve(message.data)
        return
      }

      switch (message.data) {
        case 'PAGE_NOT_FOUND':
          return reject(message.data)
        case 'EMPTY_PAGE':
          return resolve({ root: {} })
        default:
          return reject(message)
      }
    })
  } else {
    res = await makeXhrRequest(url)
  }
  if (!res || res.length === 0) {
    // return null if res is empty
    return null
  }
  return !res || res.length === 0 ? null : res
}

export function makeXhrRequest(url, options) {
  return (
    axios(url, options)
      .then((res) => res.data)
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error))
  )
}
